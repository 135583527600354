html,
body {
  padding: 0;
  margin: 0;
  font-family: Sofia Pro,-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.flag-box {
  display: inline-block;
  width: 20px;
  margin-right: 10px;
  .iti-flag {
      width: 20px;
      height: 15px;
      box-shadow: 0 0 1px 0 #888;
      background-image: url(/img/flags.jpg);
      background-repeat: no-repeat;
      background-color: #dbdbdb;
      background-position: 20px 0;
      &.my {
          height: 10px !important;
          background-position: -3578px 0;
      }
      &.hk {
          height: 14px !important;
          background-position: -2171px 0;
      }
      &.id {
          height: 14px !important;
          background-position: -2325px 0;
      }
      &.sg {
          height: 14px !important;
          background-position: -4449px 0;
      }
      &.tw {
          height: 14px !important;
          background-position: -5131px 0;
      }
      &.th {
          height: 14px !important;
          background-position: -4911px 0;
      }
      &.vn {
          height: 14px !important;
          background-position: -5434px 0;
      }
      &.ac {
        height: 10px !important;
        background-position: 0 0 !important;
      }
      
      &.ad {
        height: 14px !important;
        background-position: -22px 0 !important;
      }
      
      &.ae {
        height: 10px !important;
        background-position: -44px 0 !important;
      }
      
      &.af {
        height: 14px !important;
        background-position: -66px 0 !important;
      }
      
      &.ag {
        height: 14px !important;
        background-position: -88px 0 !important;
      }
      
      &.ai {
        height: 10px !important;
        background-position: -110px 0 !important;
      }
      
      &.al {
        height: 15px !important;
        background-position: -132px 0 !important;
      }
      
      &.am {
        height: 10px !important;
        background-position: -154px 0 !important;
      }
      
      &.ao {
        height: 14px !important;
        background-position: -176px 0 !important;
      }
      
      &.aq {
        height: 14px !important;
        background-position: -198px 0 !important;
      }
      
      &.ar {
        height: 13px !important;
        background-position: -220px 0 !important;
      }
      
      &.as {
        height: 10px !important;
        background-position: -242px 0 !important;
      }
      
      &.at {
        height: 14px !important;
        background-position: -264px 0 !important;
      }
      
      &.au {
        height: 10px !important;
        background-position: -286px 0 !important;
      }
      
      &.aw {
        height: 14px !important;
        background-position: -308px 0 !important;
      }
      
      &.ax {
        height: 13px !important;
        background-position: -330px 0 !important;
      }
      
      &.az {
        height: 10px !important;
        background-position: -352px 0 !important;
      }
      
      &.ba {
        height: 10px !important;
        background-position: -374px 0 !important;
      }
      
      &.bb {
        height: 14px !important;
        background-position: -396px 0 !important;
      }
      
      &.bd {
        height: 12px !important;
        background-position: -418px 0 !important;
      }
      
      &.be {
        height: 15px !important;
        background-position: -440px 0 !important;
      }
      
      &.bf {
        height: 14px !important;
        background-position: -460px 0 !important;
      }
      
      &.bg {
        height: 12px !important;
        background-position: -482px 0 !important;
      }
      
      &.bh {
        height: 12px !important;
        background-position: -504px 0 !important;
      }
      
      &.bi {
        height: 12px !important;
        background-position: -526px 0 !important;
      }
      
      &.bj {
        height: 14px !important;
        background-position: -548px 0 !important;
      }
      
      &.bl {
        height: 14px !important;
        background-position: -570px 0 !important;
      }
      
      &.bm {
        height: 10px !important;
        background-position: -592px 0 !important;
      }
      
      &.bn {
        height: 10px !important;
        background-position: -614px 0 !important;
      }
      
      &.bo {
        height: 14px !important;
        background-position: -636px 0 !important;
      }
      
      &.bq {
        height: 14px !important;
        background-position: -658px 0 !important;
      }
      
      &.br {
        height: 14px !important;
        background-position: -680px 0 !important;
      }
      
      &.bs {
        height: 10px !important;
        background-position: -702px 0 !important;
      }
      
      &.bt {
        height: 14px !important;
        background-position: -724px 0 !important;
      }
      
      &.bv {
        height: 15px !important;
        background-position: -746px 0 !important;
      }
      
      &.bw {
        height: 14px !important;
        background-position: -768px 0 !important;
      }
      
      &.by {
        height: 10px !important;
        background-position: -790px 0 !important;
      }
      
      &.bz {
        height: 14px !important;
        background-position: -812px 0 !important;
      }
      
      &.ca {
        height: 10px !important;
        background-position: -834px 0 !important;
      }
      
      &.cc {
        height: 10px !important;
        background-position: -856px 0 !important;
      }
      
      &.cd {
        height: 15px !important;
        background-position: -878px 0 !important;
      }
      
      &.cf {
        height: 14px !important;
        background-position: -900px 0 !important;
      }
      
      &.cg {
        height: 14px !important;
        background-position: -922px 0 !important;
      }
      
      &.ch {
        height: 15px !important;
        background-position: -944px 0 !important;
      }
      
      &.ci {
        height: 14px !important;
        background-position: -961px 0 !important;
      }
      
      &.ck {
        height: 10px !important;
        background-position: -983px 0 !important;
      }
      
      &.cl {
        height: 14px !important;
        background-position: -1005px 0 !important;
      }
      
      &.cm {
        height: 14px !important;
        background-position: -1027px 0 !important;
      }
      
      &.cn {
        height: 14px !important;
        background-position: -1049px 0 !important;
      }
      
      &.co {
        height: 14px !important;
        background-position: -1071px 0 !important;
      }
      
      &.cp {
        height: 14px !important;
        background-position: -1093px 0 !important;
      }
      
      &.cr {
        height: 12px !important;
        background-position: -1115px 0 !important;
      }
      
      &.cu {
        height: 10px !important;
        background-position: -1137px 0 !important;
      }
      
      &.cv {
        height: 12px !important;
        background-position: -1159px 0 !important;
      }
      
      &.cw {
        height: 14px !important;
        background-position: -1181px 0 !important;
      }
      
      &.cx {
        height: 10px !important;
        background-position: -1203px 0 !important;
      }
      
      &.cy {
        height: 14px !important;
        background-position: -1225px 0 !important;
      }
      
      &.cz {
        height: 14px !important;
        background-position: -1247px 0 !important;
      }
      
      &.de {
        height: 12px !important;
        background-position: -1269px 0 !important;
      }
      
      &.dg {
        height: 10px !important;
        background-position: -1291px 0 !important;
      }
      
      &.dj {
        height: 14px !important;
        background-position: -1313px 0 !important;
      }
      
      &.dk {
        height: 15px !important;
        background-position: -1335px 0 !important;
      }
      
      &.dm {
        height: 10px !important;
        background-position: -1357px 0 !important;
      }
      
      &.do {
        height: 14px !important;
        background-position: -1379px 0 !important;
      }
      
      &.dz {
        height: 14px !important;
        background-position: -1401px 0 !important;
      }
      
      &.ea {
        height: 14px !important;
        background-position: -1423px 0 !important;
      }
      
      &.ec {
        height: 14px !important;
        background-position: -1445px 0 !important;
      }
      
      &.ee {
        height: 13px !important;
        background-position: -1467px 0 !important;
      }
      
      &.eg {
        height: 14px !important;
        background-position: -1489px 0 !important;
      }
      
      &.eh {
        height: 10px !important;
        background-position: -1511px 0 !important;
      }
      
      &.er {
        height: 10px !important;
        background-position: -1533px 0 !important;
      }
      
      &.es {
        height: 14px !important;
        background-position: -1555px 0 !important;
      }
      
      &.et {
        height: 10px !important;
        background-position: -1577px 0 !important;
      }
      
      &.eu {
        height: 14px !important;
        background-position: -1599px 0 !important;
      }
      
      &.fi {
        height: 12px !important;
        background-position: -1621px 0 !important;
      }
      
      &.fj {
        height: 10px !important;
        background-position: -1643px 0 !important;
      }
      
      &.fk {
        height: 10px !important;
        background-position: -1665px 0 !important;
      }
      
      &.fm {
        height: 11px !important;
        background-position: -1687px 0 !important;
      }
      
      &.fo {
        height: 15px !important;
        background-position: -1709px 0 !important;
      }
      
      &.fr {
        height: 14px !important;
        background-position: -1731px 0 !important;
      }
      
      &.ga {
        height: 15px !important;
        background-position: -1753px 0 !important;
      }
      
      &.gb {
        height: 10px !important;
        background-position: -1775px 0 !important;
      }
      
      &.gd {
        height: 12px !important;
        background-position: -1797px 0 !important;
      }
      
      &.ge {
        height: 14px !important;
        background-position: -1819px 0 !important;
      }
      
      &.gf {
        height: 14px !important;
        background-position: -1841px 0 !important;
      }
      
      &.gg {
        height: 14px !important;
        background-position: -1863px 0 !important;
      }
      
      &.gh {
        height: 14px !important;
        background-position: -1885px 0 !important;
      }
      
      &.gi {
        height: 10px !important;
        background-position: -1907px 0 !important;
      }
      
      &.gl {
        height: 14px !important;
        background-position: -1929px 0 !important;
      }
      
      &.gm {
        height: 14px !important;
        background-position: -1951px 0 !important;
      }
      
      &.gn {
        height: 14px !important;
        background-position: -1973px 0 !important;
      }
      
      &.gp {
        height: 14px !important;
        background-position: -1995px 0 !important;
      }
      
      &.gq {
        height: 14px !important;
        background-position: -2017px 0 !important;
      }
      
      &.gr {
        height: 14px !important;
        background-position: -2039px 0 !important;
      }
      
      &.gs {
        height: 10px !important;
        background-position: -2061px 0 !important;
      }
      
      &.gt {
        height: 13px !important;
        background-position: -2083px 0 !important;
      }
      
      &.gu {
        height: 11px !important;
        background-position: -2105px 0 !important;
      }
      
      &.gw {
        height: 10px !important;
        background-position: -2127px 0 !important;
      }
      
      &.gy {
        height: 12px !important;
        background-position: -2149px 0 !important;
      }
      
      &.hk {
        height: 14px !important;
        background-position: -2171px 0 !important;
      }
      
      &.hm {
        height: 10px !important;
        background-position: -2193px 0 !important;
      }
      
      &.hn {
        height: 10px !important;
        background-position: -2215px 0 !important;
      }
      
      &.hr {
        height: 10px !important;
        background-position: -2237px 0 !important;
      }
      
      &.ht {
        height: 12px !important;
        background-position: -2259px 0 !important;
      }
      
      &.hu {
        height: 10px !important;
        background-position: -2281px 0 !important;
      }
      
      &.ic {
        height: 14px !important;
        background-position: -2303px 0 !important;
      }
      
      &.id {
        height: 14px !important;
        background-position: -2325px 0 !important;
      }
      
      &.ie {
        height: 10px !important;
        background-position: -2347px 0 !important;
      }
      
      &.il {
        height: 15px !important;
        background-position: -2369px 0 !important;
      }
      
      &.im {
        height: 10px !important;
        background-position: -2391px 0 !important;
      }
      
      &.in {
        height: 14px !important;
        background-position: -2413px 0 !important;
      }
      
      &.io {
        height: 10px !important;
        background-position: -2435px 0 !important;
      }
      
      &.iq {
        height: 14px !important;
        background-position: -2457px 0 !important;
      }
      
      &.ir {
        height: 12px !important;
        background-position: -2479px 0 !important;
      }
      
      &.is {
        height: 15px !important;
        background-position: -2501px 0 !important;
      }
      
      &.it {
        height: 14px !important;
        background-position: -2523px 0 !important;
      }
      
      &.je {
        height: 12px !important;
        background-position: -2545px 0 !important;
      }
      
      &.jm {
        height: 10px !important;
        background-position: -2567px 0 !important;
      }
      
      &.jo {
        height: 10px !important;
        background-position: -2589px 0 !important;
      }
      
      &.jp {
        height: 14px !important;
        background-position: -2611px 0 !important;
      }
      
      &.ke {
        height: 14px !important;
        background-position: -2633px 0 !important;
      }
      
      &.kg {
        height: 12px !important;
        background-position: -2655px 0 !important;
      }
      
      &.kh {
        height: 13px !important;
        background-position: -2677px 0 !important;
      }
      
      &.ki {
        height: 10px !important;
        background-position: -2699px 0 !important;
      }
      
      &.km {
        height: 12px !important;
        background-position: -2721px 0 !important;
      }
      
      &.kn {
        height: 14px !important;
        background-position: -2743px 0 !important;
      }
      
      &.kp {
        height: 10px !important;
        background-position: -2765px 0 !important;
      }
      
      &.kr {
        height: 14px !important;
        background-position: -2787px 0 !important;
      }
      
      &.kw {
        height: 10px !important;
        background-position: -2809px 0 !important;
      }
      
      &.ky {
        height: 10px !important;
        background-position: -2831px 0 !important;
      }
      
      &.kz {
        height: 10px !important;
        background-position: -2853px 0 !important;
      }
      
      &.la {
        height: 14px !important;
        background-position: -2875px 0 !important;
      }
      
      &.lb {
        height: 14px !important;
        background-position: -2897px 0 !important;
      }
      
      &.lc {
        height: 10px !important;
        background-position: -2919px 0 !important;
      }
      
      &.li {
        height: 12px !important;
        background-position: -2941px 0 !important;
      }
      
      &.lk {
        height: 10px !important;
        background-position: -2963px 0 !important;
      }
      
      &.lr {
        height: 11px !important;
        background-position: -2985px 0 !important;
      }
      
      &.ls {
        height: 14px !important;
        background-position: -3007px 0 !important;
      }
      
      &.lt {
        height: 12px !important;
        background-position: -3029px 0 !important;
      }
      
      &.lu {
        height: 12px !important;
        background-position: -3051px 0 !important;
      }
      
      &.lv {
        height: 10px !important;
        background-position: -3073px 0 !important;
      }
      
      &.ly {
        height: 10px !important;
        background-position: -3095px 0 !important;
      }
      
      &.ma {
        height: 14px !important;
        background-position: -3117px 0 !important;
      }
      
      &.mc {
        height: 15px !important;
        background-position: -3139px 0 !important;
      }
      
      &.md {
        height: 10px !important;
        background-position: -3160px 0 !important;
      }
      
      &.me {
        height: 10px !important;
        background-position: -3182px 0 !important;
      }
      
      &.mf {
        height: 14px !important;
        background-position: -3204px 0 !important;
      }
      
      &.mg {
        height: 14px !important;
        background-position: -3226px 0 !important;
      }
      
      &.mh {
        height: 11px !important;
        background-position: -3248px 0 !important;
      }
      
      &.mk {
        height: 10px !important;
        background-position: -3270px 0 !important;
      }
      
      &.ml {
        height: 14px !important;
        background-position: -3292px 0 !important;
      }
      
      &.mm {
        height: 14px !important;
        background-position: -3314px 0 !important;
      }
      
      &.mn {
        height: 10px !important;
        background-position: -3336px 0 !important;
      }
      
      &.mo {
        height: 14px !important;
        background-position: -3358px 0 !important;
      }
      
      &.mp {
        height: 10px !important;
        background-position: -3380px 0 !important;
      }
      
      &.mq {
        height: 14px !important;
        background-position: -3402px 0 !important;
      }
      
      &.mr {
        height: 14px !important;
        background-position: -3424px 0 !important;
      }
      
      &.ms {
        height: 10px !important;
        background-position: -3446px 0 !important;
      }
      
      &.mt {
        height: 14px !important;
        background-position: -3468px 0 !important;
      }
      
      &.mu {
        height: 14px !important;
        background-position: -3490px 0 !important;
      }
      
      &.mv {
        height: 14px !important;
        background-position: -3512px 0 !important;
      }
      
      &.mw {
        height: 14px !important;
        background-position: -3534px 0 !important;
      }
      
      &.mx {
        height: 12px !important;
        background-position: -3556px 0 !important;
      }
      
      &.my {
        height: 10px !important;
        background-position: -3578px 0 !important;
      }
      
      &.mz {
        height: 14px !important;
        background-position: -3600px 0 !important;
      }
      
      &.na {
        height: 14px !important;
        background-position: -3622px 0 !important;
      }
      
      &.nc {
        height: 10px !important;
        background-position: -3644px 0 !important;
      }
      
      &.ne {
        height: 15px !important;
        background-position: -3666px 0 !important;
      }
      
      &.nf {
        height: 10px !important;
        background-position: -3686px 0 !important;
      }
      
      &.ng {
        height: 10px !important;
        background-position: -3708px 0 !important;
      }
      
      &.ni {
        height: 12px !important;
        background-position: -3730px 0 !important;
      }
      
      &.nl {
        height: 14px !important;
        background-position: -3752px 0 !important;
      }
      
      &.no {
        height: 15px !important;
        background-position: -3774px 0 !important;
      }
      
      &.np {
        height: 15px !important;
        background-position: -3796px 0 !important;
      }
      
      &.nr {
        height: 10px !important;
        background-position: -3811px 0 !important;
      }
      
      &.nu {
        height: 10px !important;
        background-position: -3833px 0 !important;
      }
      
      &.nz {
        height: 10px !important;
        background-position: -3855px 0 !important;
      }
      
      &.om {
        height: 10px !important;
        background-position: -3877px 0 !important;
      }
      
      &.pa {
        height: 14px !important;
        background-position: -3899px 0 !important;
      }
      
      &.pe {
        height: 14px !important;
        background-position: -3921px 0 !important;
      }
      
      &.pf {
        height: 14px !important;
        background-position: -3943px 0 !important;
      }
      
      &.pg {
        height: 15px !important;
        background-position: -3965px 0 !important;
      }
      
      &.ph {
        height: 10px !important;
        background-position: -3987px 0 !important;
      }
      
      &.pk {
        height: 14px !important;
        background-position: -4009px 0 !important;
      }
      
      &.pl {
        height: 13px !important;
        background-position: -4031px 0 !important;
      }
      
      &.pm {
        height: 14px !important;
        background-position: -4053px 0 !important;
      }
      
      &.pn {
        height: 10px !important;
        background-position: -4075px 0 !important;
      }
      
      &.pr {
        height: 14px !important;
        background-position: -4097px 0 !important;
      }
      
      &.ps {
        height: 10px !important;
        background-position: -4119px 0 !important;
      }
      
      &.pt {
        height: 14px !important;
        background-position: -4141px 0 !important;
      }
      
      &.pw {
        height: 13px !important;
        background-position: -4163px 0 !important;
      }
      
      &.py {
        height: 11px !important;
        background-position: -4185px 0 !important;
      }
      
      &.qa {
        height: 8px !important;
        background-position: -4207px 0 !important;
      }
      
      &.re {
        height: 14px !important;
        background-position: -4229px 0 !important;
      }
      
      &.ro {
        height: 14px !important;
        background-position: -4251px 0 !important;
      }
      
      &.rs {
        height: 14px !important;
        background-position: -4273px 0 !important;
      }
      
      &.ru {
        height: 14px !important;
        background-position: -4295px 0 !important;
      }
      
      &.rw {
        height: 14px !important;
        background-position: -4317px 0 !important;
      }
      
      &.sa {
        height: 14px !important;
        background-position: -4339px 0 !important;
      }
      
      &.sb {
        height: 10px !important;
        background-position: -4361px 0 !important;
      }
      
      &.sc {
        height: 10px !important;
        background-position: -4383px 0 !important;
      }
      
      &.sd {
        height: 10px !important;
        background-position: -4405px 0 !important;
      }
      
      &.se {
        height: 13px !important;
        background-position: -4427px 0 !important;
      }
      
      &.sg {
        height: 14px !important;
        background-position: -4449px 0 !important;
      }
      
      &.sh {
        height: 10px !important;
        background-position: -4471px 0 !important;
      }
      
      &.si {
        height: 10px !important;
        background-position: -4493px 0 !important;
      }
      
      &.sj {
        height: 15px !important;
        background-position: -4515px 0 !important;
      }
      
      &.sk {
        height: 14px !important;
        background-position: -4537px 0 !important;
      }
      
      &.sl {
        height: 14px !important;
        background-position: -4559px 0 !important;
      }
      
      &.sm {
        height: 15px !important;
        background-position: -4581px 0 !important;
      }
      
      &.sn {
        height: 14px !important;
        background-position: -4603px 0 !important;
      }
      
      &.so {
        height: 14px !important;
        background-position: -4625px 0 !important;
      }
      
      &.sr {
        height: 14px !important;
        background-position: -4647px 0 !important;
      }
      
      &.ss {
        height: 10px !important;
        background-position: -4669px 0 !important;
      }
      
      &.st {
        height: 10px !important;
        background-position: -4691px 0 !important;
      }
      
      &.sv {
        height: 12px !important;
        background-position: -4713px 0 !important;
      }
      
      &.sx {
        height: 14px !important;
        background-position: -4735px 0 !important;
      }
      
      &.sy {
        height: 14px !important;
        background-position: -4757px 0 !important;
      }
      
      &.sz {
        height: 14px !important;
        background-position: -4779px 0 !important;
      }
      
      &.ta {
        height: 10px !important;
        background-position: -4801px 0 !important;
      }
      
      &.tc {
        height: 10px !important;
        background-position: -4823px 0 !important;
      }
      
      &.td {
        height: 14px !important;
        background-position: -4845px 0 !important;
      }
      
      &.tf {
        height: 14px !important;
        background-position: -4867px 0 !important;
      }
      
      &.tg {
        height: 13px !important;
        background-position: -4889px 0 !important;
      }
      
      &.th {
        height: 14px !important;
        background-position: -4911px 0 !important;
      }
      
      &.tj {
        height: 10px !important;
        background-position: -4933px 0 !important;
      }
      
      &.tk {
        height: 10px !important;
        background-position: -4955px 0 !important;
      }
      
      &.tl {
        height: 10px !important;
        background-position: -4977px 0 !important;
      }
      
      &.tm {
        height: 14px !important;
        background-position: -4999px 0 !important;
      }
      
      &.tn {
        height: 14px !important;
        background-position: -5021px 0 !important;
      }
      
      &.to {
        height: 10px !important;
        background-position: -5043px 0 !important;
      }
      
      &.tr {
        height: 14px !important;
        background-position: -5065px 0 !important;
      }
      
      &.tt {
        height: 12px !important;
        background-position: -5087px 0 !important;
      }
      
      &.tv {
        height: 10px !important;
        background-position: -5109px 0 !important;
      }
      
      &.tw {
        height: 14px !important;
        background-position: -5131px 0 !important;
      }
      
      &.tz {
        height: 14px !important;
        background-position: -5153px 0 !important;
      }
      
      &.ua {
        height: 14px !important;
        background-position: -5175px 0 !important;
      }
      
      &.ug {
        height: 14px !important;
        background-position: -5197px 0 !important;
      }
      
      &.um {
        height: 11px !important;
        background-position: -5219px 0 !important;
      }
      
      &.un {
        height: 14px !important;
        background-position: -5241px 0 !important;
      }
      
      &.us {
        height: 11px !important;
        background-position: -5263px 0 !important;
      }
      
      &.uy {
        height: 14px !important;
        background-position: -5285px 0 !important;
      }
      
      &.uz {
        height: 10px !important;
        background-position: -5307px 0 !important;
      }
      
      &.va {
        height: 15px !important;
        background-position: -5329px 0 !important;
      }
      
      &.vc {
        height: 14px !important;
        background-position: -5346px 0 !important;
      }
      
      &.ve {
        height: 14px !important;
        background-position: -5368px 0 !important;
      }
      
      &.vg {
        height: 10px !important;
        background-position: -5390px 0 !important;
      }
      
      &.vi {
        height: 14px !important;
        background-position: -5412px 0 !important;
      }
      
      &.vn {
        height: 14px !important;
        background-position: -5434px 0 !important;
      }
      
      &.vu {
        height: 12px !important;
        background-position: -5456px 0 !important;
      }
      
      &.wf {
        height: 14px !important;
        background-position: -5478px 0 !important;
      }
      
      &.ws {
        height: 10px !important;
        background-position: -5500px 0 !important;
      }
      
      &.xk {
        height: 15px !important;
        background-position: -5522px 0 !important;
      }
      
      &.ye {
        height: 14px !important;
        background-position: -5544px 0 !important;
      }
      
      &.yt {
        height: 14px !important;
        background-position: -5566px 0 !important;
      }
      
      &.za {
        height: 14px !important;
        background-position: -5588px 0 !important;
      }
      
      &.zm {
        height: 14px !important;
        background-position: -5610px 0 !important;
      }
      
      &.zw {
        height: 10px !important;
        background-position: -5632px 0 !important;
      }
      
  }
}
